

















































import { Component, Vue } from 'vue-property-decorator';

import { ISiteTrafficTaskCreate } from '@/interfaces/site-traffic';
import {
dispatchCreateSiteTrafficTask,
dispatchGetSiteTrafficTasks,
} from '@/store/site-traffic/actions';
import { fileRules, nameRules } from './validators';

@Component
export default class SiteTrafficCreateTask extends Vue {
  public file: File | null = null;
  public submitting = false;
  public valid = false;
  public name = '';
  public description = '';
  public nameRules = nameRules;
  public fileRules = fileRules;

  public async mounted() {
    await dispatchGetSiteTrafficTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.name = this.description = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const createdTask: ISiteTrafficTaskCreate = {
        name: this.name,
        description: this.description || undefined,
        file: this.file,
      };
      await dispatchCreateSiteTrafficTask(this.$store, createdTask);
      this.$router.push('/main/site-traffic');
    }
  }

}
